.App {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}